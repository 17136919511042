import React, { useEffect, useRef, useState } from "react";
import { muAxios } from "../../api/axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Link, useNavigate } from "react-router-dom";

function Courses() {

  const {user} = useAuthContext();
  const [courses,setCourses] = useState([]);
  const [errMsg,setErrMsg] = useState("");
  const [progress,setProgress] = useState([]);
  const nav = useNavigate();
  

  useEffect(() => {
    muAxios.get('/api/mu/get/courses', {headers: {
      accessToken: user.token
    }}).then((response) => {
      if(response.data){
        setCourses(response.data)
      }else{
        setErrMsg("Cannot access courses at this moment. Please try again later.")
      }
    })
  }, [])

  useEffect(() => {
    muAxios.get(`/api/mu/get/progress`, {headers: {
        accessToken: user.token
    }}).then((response) => {
        const json = response.data
        if(json){
            setProgress(json.progress)
        }
    })
}, []);

const test = progress[0];

  return (
    <div className="App">
      <div className="container-fluid mt-3">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            <h1 className="mt-4 header-title">M-UNIVERSITY</h1>
            <p className="mb-5" style={{fontSize:"1rem"}}>“Wisdom is not a product of schooling but of the lifelong attempt to acquire it.” — <b>Albert Einstein</b></p>

          {courses.map((val,i) => {
            let thisButton = 1;
            let thisProgress = 0;
            const toCourse = `/courses/${val.shortlink}`;
            const logo = process.env.REACT_APP_MU + val.logoFile
            const shortlink = val.shortlink;

            return (
              <div class="card my-3">
              <div class="row">
  
                <div class="col-md-4 d-flex justify-content-center align-items-center">
                  <img src={logo} width="300" class="img-fluid rounded-start my-2" alt={shortlink} />
                </div>
                <div class="col-md-8 d-flex justify-content-center align-items-center">
                  <div class="card-body">
                    <h5 class="card-title">{val.courseName}</h5>
                    <p style={{marginTop:'25px', textAlign:'justify'}}>
                      {val.description}
                    </p>
                    <div className="d-grid gap-2">
                      {progress.map((val,i) => {
                        if(val.shortlink === shortlink){
                          thisProgress = 1
                        }
                      })}
                      {thisButton === 1 ? (<>{thisProgress === 1 ? (<Link className="btn btn-primary mt-3" to={toCourse}>View Chapters</Link>) : thisProgress === 0 ? (<a className="btn btn-success mt-3" href={`https://api.whatsapp.com/send?phone=601110612500&text=Mirads%20University%20-%20${val.courseName}`} target="_blank">Enroll Now</a>) : (<></>)}</>) : (<></>)}
                      
                      
                      
                     
                      </div>
                     
              
                  </div>
 
                </div>
                
              </div>
            </div>
            )
          })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
