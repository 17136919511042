import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import { useAdminContext } from '../../hooks/useAdminContext';

function AdminPanel({setNavbar, props}) {

  const {admin} = useAdminContext();
  const [active, setActive] = useState(false);
  const [activeMsg, setActiveMsg] = useState("");
  const [allreq, setallreq] = useState([])
  const navigate = useNavigate();
  
  useEffect(() => {
    setNavbar(false);
})

useEffect(() => {
  axios.get('/api/admin/get/mace/access', {headers: {
    adminToken: admin.token.adminToken
  }}).then((response) => {
    setallreq(response.data)
  }).catch((err) => console.log(err))
}, [])



  return (
    <div className='App'>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12 mb-4">
        <h1 className="mt-4 header-title text-center">ADMIN PANEL</h1>
        </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-md-6 text-center'>
          <div class="card">
            <div class="card-body">
              Users List
              <div className="d-grid my-3 gap-2">
                  <Link className='btn btn-sm btn-primary mt' to='/admin/users'>Go To List</Link>
                </div>
            </div>
          </div>
          </div>
          <div className='col-md-6 text-center'>
          <div class="card">
            <div class="card-body">
              MU Register
              <div className="d-grid my-3 gap-2">
                  <Link className='btn btn-sm btn-primary mt' to='/admin/register/mu'>Register</Link>
                </div>
            </div>
          </div>
          </div>
          <div className='col-md-12 text-center'>
          <div class="card">
            <div class="card-body">
              Approve MACE Form
              <div className="d-grid my-3 gap-2">
                
		<table class="table table-responsive table-striped">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Username</th>
                            <th scope="col">Link</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>  

		{allreq.map((val,key) => {

                  const apprvMace = () => {
                    const sendData = {username: val.username}
                    axios.post('/api/admin/approve/mace/access', sendData, {headers: {
                      adminToken: admin.token.adminToken
                    }}).then((response) => {
                      if(response.data.succ){
                        window.location.reload()
                      }
                    })
                  }

                  const delMace = () => {
                    const sendData = {username: val.username}
                    if(window.confirm(`Are you sure you want to delete entry from ${val.username}`)){
                      axios.post('/api/admin/delete/mace/access', sendData, {headers: {
                        adminToken: admin.token.adminToken
                      }}).then((response) => {
                        if(response.data.succ){
                          window.location.reload()
                        }
                      })
                    }else{

                    }
                    
                  }
                    
                    return (
                   
                        <tbody>
                          <tr>
                            <th scope="row">{key+1}</th>
                            <td>{val.username}</td>
                            <td><a className='btn btn-sm btn-primary' href={val.link} target='_blank'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                            <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                          </svg> Visit</a></td>
                            <td><button className='btn btn-sm btn-success mx-1' onClick={apprvMace}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"/>
                            </svg></button>
                            <button className='btn btn-sm btn-danger' onClick={delMace}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                            </svg></button>
                            </td>
                          </tr>
                        </tbody>
                    )
                  })}
			</table>
                </div>
            </div>
          </div>
          </div>
        </div>
        </div>
        </div>
  )
}

export default AdminPanel
